import barba from '@barba/core';
//import LocomotiveScroll from 'locomotive-scroll';
import { gsap, Power3} from "gsap";
//import { CSSRulePlugin } from "gsap/CSSRulePlugin";
//import { Draggable } from "gsap/Draggable";
//import { SplitText } from "gsap/SplitText";
//import { ScrollToPlugin } from "gsap/ScrollToPlugin";
//import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
//import { CustomEase } from "gsap/CustomEase";
//import { InertiaPlugin } from "gsap/InertiaPlugin";




//gsap.registerPlugin(CSSRulePlugin, Draggable);
//gsap.registerPlugin(CSSRulePlugin, ScrollTrigger, Draggable, CustomEase, InertiaPlugin);



(function( $ ) {

	
	///////
	//BARBA
	///////
	barba.init({
		transitions: [{
			name: 'default-transition',
			beforeEnter() {
				// update the menu based on user navigation
				//menu.update();
				//alert("joe");
				
			},
			leave(data) {
				menuActiveState();
				closeSecondaryNavigation();
				closeSearch();
				return gsap.to(data.current.container, {
					opacity: 0, duration: 0.3, ease: Power3.easeOut
				});
			},
			beforeEnter(data){
				
				animatePrimaryNavHeight();
			},
			enter(data) {
				
				return gsap.from(data.next.container, {
					opacity: 0, duration: 0.4, ease: Power3.easeOut
				});
			},
			after(data) {
				initSliders();
				initAnchors();
			}
		}]
	});




	////////
	//SEARCH
	////////

	function openSearch(){
		$("html").addClass("-search-open -no-scroll");
		$("#search").addClass("-open");
	}
	function closeSearch(){
		$("html").removeClass("-search-open -no-scroll");
		$("#search").removeClass("-open");
	}
	$("body").on("click", ".-js-toggle-search", function(e){
		e.preventDefault();
		if($("html").hasClass("-search-open")){
			closeSearch();
		}
		else{
			openSearch();
		}
	});
	$("body").on("click", ".-js-close-search", function(e){
		closeSearch();
	});

	


	////////////////
	//MENU SECONDARY 
	////////////////

	function openSecondaryNavigation(){
		$("html").addClass("-secondary-navigation-open -no-scroll");
		$("#secondary-navigation").addClass("-open");
		$("#hamburger-lines").addClass("-open");
		$(".mobile-main>li").removeClass("-open");

		gsap.set(".mobile-main>li>a", {y:"160%"});
		$(".mobile-main>li>a").each(function(i,v){
			setTimeout(function(){
				gsap.to(v, {y:"0%",duration:0.5, ease:Power3.easeOut});
			},(i*80)+700);
		});

		


		
	}

	function closeSecondaryNavigation(){
		$("html").removeClass("-secondary-navigation-open -no-scroll");
		$("#secondary-navigation").removeClass("-open");
		$("#hamburger-lines").removeClass("-open");
	}

	$("body").on("click", ".-js-toggle-secondary-navigation", function(e){
		e.preventDefault();
		if($("html").hasClass("-secondary-navigation-open")){
			closeSecondaryNavigation();
		}
		else{
			openSecondaryNavigation();
		}
	});

	$("body").on("click", ".-js-close-secondary-navigation", function(e){
		closeSecondaryNavigation();
	});


	//enable toggle arrow icon in mobile menu
	$("body").on("click", ".mobile-main li .icon", function(e){
		e.preventDefault();
		var li = $(this).parent();
		$(li).toggleClass("-open")
	})

	function cloneDesktoptoMobileMenu(){
		var html = $("#primary-menu>ul").html();
		$(".secondary-menu-mobile ul.mobile-main").append(html);
		menuActiveState();
	}

	

	cloneDesktoptoMobileMenu();



	/////////
	//SLIDERS
	/////////
	function initSliders(){
		$(".slider").each(function(i,v){
			initSlider(v);
		});
	}
	
	function initSlider(sliderEl){

		var sliderNavEl = $(sliderEl).prev(".slider-navigation")[0];
		var sliderNavEl = $(sliderEl).prev(".slider-navigation")[0];
		var sliderImgEl = $(".slider-image");
		
		console.log(sliderImgEl);
		var sliderNavElItem = $("ul>li", sliderNavEl);
		

		var activeSlide = 1;
		var nSlides = $("ul>li",sliderEl).length;
		$("ul", sliderImgEl).css({"width": (nSlides*100)+"%"});
		$("ul", sliderEl).css({"width": (nSlides*100)+"%"});
		$("ul>li", sliderEl).css({"width": (100/nSlides)+"%"});

		var duration = 10000;
		var play = true;
		
		$("body").on("click", '.slider-navigation ul li', function(e){
			e.preventDefault();
			play = false;
			var i = $(this).prevAll().length+1;
			setSlide(i, false);
		});

		function playSlides(){
			setTimeout(function(){
				if(play){
					
					var toSlide = activeSlide+1;
					if(toSlide > nSlides){
						var toSlide = 1;
					}
					setSlide(toSlide);
					playSlides();
				}
			}, duration);
		}

		function setSlide(toSlide, animate = true){
			
			activeSlide = toSlide;
			var height = $("ul>li:nth-child("+toSlide+")", sliderEl).outerHeight();
			$("ul", sliderEl).css({"height": height});
			$("ul>li", sliderEl).removeClass("-active -animate");
			$("ul>li", sliderNavEl).removeClass("-active -animate");
			$("ul>li", sliderImgEl).removeClass("-active -animate");
			var svg = $("ul>li:nth-child("+toSlide+") svg .rotate", sliderNavEl)[0];
			if(animate){
				$("ul>li:nth-child("+toSlide+")", sliderNavEl).addClass("-active -animate");
				$("ul", sliderImgEl).css({"left": -((toSlide-1)*100)+"%"});
				$("ul", sliderEl).css({"left": -((toSlide-1)*100)+"%"});
				$(svg).css({'stroke-dashoffset': '52px'});
				$(svg).animate({'stroke-dashoffset': '0px'}, duration);
			}
			else{
				$("ul>li:nth-child("+toSlide+")", sliderEl).addClass("-active");
				$("ul>li:nth-child("+toSlide+")", sliderNavEl).addClass("-active");
				$("ul", sliderEl).css({"left": -((toSlide-1)*100)+"%"});
				$(svg).css({'stroke-dashoffset': '0px'});
			}	
			
		}


		setSlide(activeSlide);
		playSlides();
		
	}

	initSliders();



	function initTextSliders(){
		$(".text-slider").each(function(i,v){
			initTextSlider(v);
		});
	}
	
	function initTextSlider(sliderEl){
		var sliderNavEl = $(sliderEl);

		var activeSlide = 1;
		var nSlides = $("ul>li",sliderEl).length;
		var duration = 10000;
		var play = true;
		
		$("body").on("click", '.text-slider ul li', function(e){
			e.preventDefault();
			play = false;
			var i = $(this).prevAll().length+1;
			setTextSlide(i, false);
		});

		function playTextSlides(){
			setTimeout(function(){
				if(play){
					
					var toSlide = activeSlide+1;
					if(toSlide > nSlides){
						var toSlide = 1;
					}
					setTextSlide(toSlide);
					playTextSlides();
				}
			}, duration);
		}

		function setTextSlide(toSlide, animate = true){
			
			activeSlide = toSlide;
			$("ul>li", sliderNavEl).removeClass("-active -animate");
			var svg = $("ul>li:nth-child("+toSlide+") svg .rotate", sliderNavEl)[0];
			if(animate){
				$("ul>li:nth-child("+toSlide+")", sliderEl).addClass("-active -animate");
				$("ul>li:nth-child("+toSlide+")", sliderNavEl).addClass("-active -animate");
				$(svg).css({'stroke-dashoffset': '52px'});
				$(svg).animate({'stroke-dashoffset': '0px'}, duration);
			}
			else{
				$("ul>li:nth-child("+toSlide+")", sliderEl).addClass("-active");
				$("ul>li:nth-child("+toSlide+")", sliderNavEl).addClass("-active");
				$(svg).css({'stroke-dashoffset': '0px'});
			}	
			
		}


		setTextSlide(activeSlide);
		playTextSlides();
		
	}

	initTextSliders();


	/////////////
	//HERO SLIDER
	/////////////


	function initHeroSliders(){
		$(".auto-slider").each(function(i,v){
			initHeroSlider(v);
		});
	}

	function initHeroSlider(v){
		var slide = 1;

		function slideTo(slide,v){
			$("li",v).removeClass("-show");
			$("li:nth-child("+slide+")",v).addClass("-show");
			setTimeout(function(){
				console.log(slide,v);
				var l = $("li",v).length;
				if(slide < l){
					slide++;
				}
				else{
					slide = 1;
				}
				
				slideTo(slide,v);
			},5000);
		}

		slideTo(slide,v);
		
	}
	initHeroSliders();



	/////////
	//ANCHORS
	/////////

	function initAnchors(){
		
		$("body").on("click", ".group-sub-links a", function(e){
			e.preventDefault();
			var i = $(this).prevAll().length+2;
			var scrollTop = $(".page-container").scrollTop();
			
			var s = $("section:nth-child("+i+")");
			if(s.length){
				var so = $(s).offset().top;
				var to = scrollTop+so-59;
				//$(".page-container").animate({"scrollTop":to},400);
				gsap.to(".page-container", {"scrollTop":to, duration:1, ease:Power3.easeInOut});
			}
			
		});
	}

	initAnchors();

	//////
	//FORM
	//////

	$("body").on("focus", ".form input", function(e){
		$(this).parent().addClass("-focus");
	});
	$("body").on("blur", ".form input", function(e){
		var v = $(this).val();
		if(v === ""){
			$(this).parent().removeClass("-focus");
		}
		
	});



	//MENU ACTIVE STATE
	function menuActiveState(){
		var pathname = window.location.pathname;
		//alert(pathname);
		$("#primary-menu ul li, ul.mobile-main li").each(function(i,v){
			var url = $(v).attr("data-url");
			
			if(url){
				if(pathname.startsWith(url)){
					$(v).addClass("-active");
				}
				else{
					$(v).removeClass("-active");
				}
			}
		});
	}
	



	/////
	//FAQ
	/////

	$("body").on("click", ".faq-item:not(.-open) .head", function(){
		var faqItemEl = $(this).parent();
		var innerHeight = $(".content", faqItemEl).height();
		gsap.to(faqItemEl, {"height": innerHeight+60, duration: 0.5, ease: Power3.easeOut});
		$(faqItemEl).siblings().removeClass("-open");
		$(faqItemEl).addClass("-open");
	});
	$("body").on("click", ".faq-item.-open .head", function(){
		var faqItemEl = $(this).parent();
		
		gsap.to(faqItemEl, {"height": 60, duration: 0.5, ease: Power3.easeOut});
		$(faqItemEl).removeClass("-open");
	});


	////////
	//SCROLL
	////////

	$(".page-container").on("scroll", function(e){
		setPrimaryNavHeight();
	});
	$(window).on("resize", function(e){
		setPrimaryNavHeight();
	});


	function setPrimaryNavHeight(){
		var h = primaryNavHeightCalc();
		var primaryNav = $("#primary-navigation");
		$(primaryNav).css({"height": h+"px"});
	}
	function animatePrimaryNavHeight(){
		var h = primaryNavHeightCalc();
		var primaryNav = $("#primary-navigation");
		$(primaryNav).animate({"height": h+"px"},300);
	}


	function primaryNavHeightCalc(){
		var scrollTop = $(".page-container").scrollTop();
		var winW = $(window).width();
		
		if(winW > 767){
			if(scrollTop == 0){
				var h = 72;
			}
			else if(scrollTop > 0 && scrollTop < 144){
				var h = 72-(scrollTop/12);
			}
			else if(scrollTop >= 144){
				var h = 60;
			}

		}
		else{
			var h = 60;
		}
		return 72;
		//return h;
	}


	//initScroll();


} ) ( jQuery );